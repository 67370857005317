<script>
const layoutTypes = {
  detailed: defineAsyncComponent(() => import('./View/detailed')),
  limited: defineAsyncComponent(() => import('./View/limited')),
  mobile: defineAsyncComponent(() => import('./View/mobile')),
};

export default defineNuxtComponent({
  name: 'TicketCard',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    layoutType: {
      type: String,
      required: false,
      default: 'detailed',
    },
  },
  computed: {
    layoutTypeComponent() {
      return layoutTypes[this.layoutType];
    },
  },
});
</script>

<template>
  <div class="rounded bg-white px-6 py-4.5">
    <keep-alive>
      <component :is="layoutTypeComponent" :data="data" />
    </keep-alive>
  </div>
</template>
