<script setup lang="ts">
import { IconCalendarDue } from '@tabler/icons-vue';

import { formatPriority, getStatusColor } from '../utils/common';
import CommentsCounter from '~/components/atoms/CommentsCounter/index.vue';
import Label from '~/components/atoms/Label/index.vue';
import PriorityIcon from '~/components/atoms/PriorityIcon/index.vue';
import { fetchAvatarImage } from '~/utilities/avatar-utilities';
import { formatShort, getTimeFromDate } from '~/utilities/date-utilities';

defineProps<{ data: any }>();

const { $store } = useNuxtApp();

function onTicketCheckboxClick(ticket: any) {
  const ticketIndex = $store.getters[
    'TicketsModuleStore/selectedTickets'
  ].findIndex(element => element.id === ticket.id);

  if (ticketIndex > -1)
    $store.commit('TicketsModuleStore/removeSelectedTickets', ticketIndex);
  else
    $store.commit('TicketsModuleStore/addSelectedTickets', ticket);
}

function isTicketSelected(ticketId: number) {
  return $store.getters['TicketsModuleStore/selectedTickets'].some(
    ticket => ticket.id === ticketId,
  );
}
</script>

<template>
  <div
    class="mt-2 flex cursor-pointer flex-col gap-2 rounded bg-white p-4 sm:flex-row sm:flex-wrap md:gap-4"
  >
    <div
      v-if="$auth.can('ticket-export', data.abilities)"
      class="md:order-0 hidden md:flex"
    >
      <trailblazer-checkbox
        :checked="isTicketSelected(data.id)"
        @click.stop="onTicketCheckboxClick(data)"
      />
    </div>
    <div
      class="order-1 flex flex-wrap items-center gap-4 border-b border-neutral-3 pb-4 sm:flex-nowrap sm:border-none sm:pb-0 md:w-48 md:flex-col md:items-start md:gap-1"
    >
      <strong class="w-32 truncate">{{ data.code }}</strong>
      <span class="text-neutral-2">{{
        getTimeFromDate(data.ticket_date)
      }}</span>

      <PriorityIcon
        v-if="data.priority"
        class="flex md:hidden"
        :priority="formatPriority(data.priority)"
      />

      <Label
        class="ml-auto sm:hidden"
        :color="getStatusColor(data?.status?.name)"
        :label="data?.status?.name"
      />

      <span
        v-if="data.deadline_date"
        class="-mt-4 flex w-full items-center gap-1 text-sm text-neutral-2 sm:m-0 sm:w-auto sm:text-base"
      >
        <IconCalendarDue /> {{ formatShort(data.deadline_date) }}
      </span>
    </div>

    <div class="hidden w-8 md:order-2 md:mx-4 md:flex">
      <PriorityIcon
        v-if="data.priority"
        :default-size="false"
        :priority="formatPriority(data.priority)"
        class="h-8 w-8"
      />
    </div>

    <div
      class="order-2 flex w-full grow flex-col gap-8 sm:order-3 sm:flex-row lg:order-3 lg:flex-1 lg:gap-4"
    >
      <div class="flex w-1/2 flex-1 flex-col">
        <strong class="mb-1">{{ data.title }}</strong>
        <p class="line-clamp-2 text-neutral-2">
          {{ data.description }}
        </p>
      </div>
      <div class="flex w-1/2 flex-1 flex-col">
        <strong class="mb-1">{{ __('Action') }}</strong>
        <p class="line-clamp-2 text-neutral-2">
          {{ data.action || '-' }}
        </p>
      </div>
    </div>

    <div
      class="order-3 ml-auto flex w-full border-t border-neutral-3 pt-4 sm:order-2 sm:w-64 sm:justify-end sm:border-none sm:pt-0 lg:order-4"
    >
      <div class="flex w-full items-start gap-4">
        <trailblazer-chip type="neutral" class="order-3 ml-auto sm:order-none">
          <div class="max-w-40 truncate text-center font-medium">
            {{ __(data?.ticket_category?.name) }}
          </div>
        </trailblazer-chip>
        <trailblazer-avatar
          :href="fetchAvatarImage(data?.user?.avatar)"
          :name="data?.user?.name"
        />
        <CommentsCounter
          class="mt-1"
          :comments-number="data.ticket_comment_count"
        />
      </div>
    </div>
  </div>
</template>
